import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from './Footer';
import { getBrandId, getDomain } from '../../config';
import withGeoLocation from '../../utils/withGeoLocation';

const domainDataQuery = gql`
  query domainData($domain: String!, $locale: String!) {
    domainData(domain: $domain, locale: $locale) {
      data {
        footerDescription
      }
    }
  }
`;

const profileQuery = gql`
  query getProfile($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        currency
        country
      }
    }
  }
`;

export default compose(
  withRouter,
  withGeoLocation,
  connect(({ auth: { uuid, logged } }) => ({ uuid, logged })),
  graphql(domainDataQuery, {
    options({
      match: {
        params: { lang: locale },
      },
    }) {
      return {
        variables: {
          domain: getDomain(),
          locale,
        },
      };
    },
    skip: ({ showFooterDescription }) => !showFooterDescription,
    name: 'domainData',
  }),
  graphql(profileQuery, {
    options: ({ uuid }) => ({
      variables: {
        brandId: getBrandId(),
        playerUUID: uuid,
      },
      pollInterval: 600000,
      fetchPolicy: 'network-only',
    }),
    skip: ({ logged }) => true || !logged,
    name: 'profileData',
  })
)(Footer);
